<template>
  <div>
    <base-select
      v-show="options.length"
      v-validate="options.length ? 'required' : ''"
      :name="name"
      v-model="_value"
      :options="options"
      :reduce="opt => opt.code"
      :validations="validations"
      :clearable="false"
      :placeholder="'* ' + $t('Chose your province')"
      >
    </base-select>
    <base-input
      v-show="!options.length"
      v-validate="''"
      :name="name"
      type="text"
      v-model="_value"
      :validations="validations"
      :placeholder="$t('address.Province')"
      @blur="$emit('formValidity')"
      />
  </div>
</template>

<script>
import { find, debounce } from 'lodash'
import Config from '@/config'
import BaseInput from '@/components/Form/BaseInput'
import BaseSelect from '@/theme/components/Form/BaseSelect'

export default {
  inject: ['$validator'],
  props: {
    name: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: String,
      default: () => ''
    },
    countryId: {
      type: String,
      default: () => Config.Theme.default.country
    },
    validations: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  mounted () {
    this.$store.dispatch('checkout/loadCountries')
  },
  computed: {
    available_regions () {
      if (this.$store.state.checkout && this.$store.state.checkout.countries.length > 0) {
        const countryFound = find(this.$store.state.checkout.countries, { 'id': this.countryId })
        if (countryFound && countryFound.available_regions) {
          let arrCode = [...new Set(countryFound.available_regions.map(data => data.code))]
          let arrId = [...new Set(countryFound.available_regions.map(data => data.id))]
          let arrName = [...new Set(countryFound.available_regions.map(data => data.name))]

          let obj = {}
          let arr = []
          var i = 0
          for (i = 0; i < arrName.length; i++) {
            obj = {}
            obj.code = arrCode[i]
            obj.name = arrName[i]
            obj.id = arrId[i]
            arr[i] = obj
          }
          return arr
        } else {
          return []
        }
      }
      return []
    },
    _value: {
      get () {
        return this.value
      },
      set: debounce(function (newValue) {
        this.$emit('input', newValue)
      }, 500)
    },
    options () {
      if (this.available_regions) {
        return this.available_regions.map((item, index) => ({ key: index, code: item.code, label: item.name }))
      } else {
        return this.available_regions
      }
    }
  },
  components: {
    BaseSelect,
    BaseInput
  }
}
</script>
